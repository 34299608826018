import { render, staticRenderFns } from "./PwdChange.vue?vue&type=template&id=035b4f73"
import script from "./PwdChange.vue?vue&type=script&lang=js"
export * from "./PwdChange.vue?vue&type=script&lang=js"
import style0 from "./PwdChange.vue?vue&type=style&index=0&id=035b4f73&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports